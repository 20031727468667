import "./customer-edit.scss";
import Modal from "./modal";
import ErrorModal from "./error-modal";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import TextInput from "./text-input";
import Button from "./button";
import Row from "./row";
import useCRUD from "../hook/useCRUD";
import useBL from "../hook/useBL";
import {Edit, Trash} from "./icons";
import PasswordInput from "./password-input";
import config from "../config.json";
import Checkbox from "./checkbox";
import {displaySource} from "../functions";

export default function CustomerEdit({user, onDismiss}) {
  
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [pos, setPos] = useState([]);
  const [editPOS, setEditPOS] = useState({});
  const [userProps, setUserProps] = useState(user ?? {});
  const [copyInvoiceAddress, setCopyInvoiceAddress] = useState(null);
  const [editId, setEditId] = useState(0);
  const [deletePosModalOpen, setDeletePosModalOpen] = useState(false);
  const [selectedPos, setSelectedPos] = useState({});
  const [deletePosConfirmedModalOpen, setDeletePosConfirmedModalOpen] = useState(false);
  const [successMessageForDelete, setSuccessMessageForDelete] = useState("");

  const crud = useCRUD();
  const bl = useBL();

  const validate = Yup.object({
    email: Yup.string().email('Bitte geben Sie eine gültige E-Mail Adresse ein'),
  });

  const posvalidate = Yup.object({
    customer_number : Yup.string().required('Bitte geben Sie eine Kundennummer ein'),
    invoice_name : Yup.string().required('Bitte geben Sie eine Bezeichnung ein'),
    invoice_street : Yup.string().required('Bitte geben Sie eine Straße ein'),
    invoice_postal_code : Yup.string().required('Bitte geben Sie eine PLZ ein'),
    invoice_city : Yup.string().required('Bitte geben Sie eine Stadt ein'),
    shipping_name : Yup.string()
      .test('shipping_name', 'Bitte geben Sie eine Bezeichnung ein', value => copyInvoiceAddress || value),
    shipping_street : Yup.string()
      .test('shipping_street', 'Bitte geben Sie eine Straße ein', value => copyInvoiceAddress || value),
    shipping_postal_code : Yup.string()
      .test('shipping_postal_code', 'Bitte geben Sie eine PLZ ein', value => copyInvoiceAddress || value),
    shipping_city : Yup.string()
      .test('shipping_city', 'Bitte geben Sie eine Stadt ein', value => copyInvoiceAddress || value),
  });

  const initialUserValues = {
    id: userProps.id,
    username: userProps.username ? userProps.username : '',
    email: userProps.email ? userProps.email : '',
    first_name: userProps.first_name ? userProps.first_name : '',
    last_name: userProps.last_name ? userProps.last_name : '',
    owner_id : userProps.owner_id ? userProps.owner_id : '0',
    password: '',
    is_sales : '0',
    is_field_sales : '0',
    is_online_sales : '0',
    is_credit_excluded :parseInt(userProps.is_credit_excluded ?? "0"),
  };

  const submit = async (values, {setSubmitting}) => {
    
    let items = {...values, is_credit_excluded: values.is_credit_excluded ? '1' : 0};
    for (const key in items) {
      if (items[key] && items[key].length > 0) {
        items[key] = items[key].trim();
      }
    }

    async function asyncMap(arr, cb, ...args){
      return await Promise.all(
        arr.map(async (item) => {
          return await cb(item, ...args);
        })
      );
    }

    async function uploadAddresses(shop, ownerId) {
      return new Promise(async resolve => {
        let addressIDs = {};

        let invoiceAddr = shop.invoice_address;
        let shippingAddr = copyInvoiceAddress ? shop.invoice_address : shop.shipping_address;

        if (shop.invoice_address_id) {
          await crud.data.update({entity: 'address', id: shop.invoice_address_id, update: invoiceAddr});
          addressIDs.invoice_address_id = shop.invoice_address_id;
        } else {
          invoiceAddr = {...invoiceAddr, owner_id: ownerId};
          await crud.data.create({entity: 'address', item: invoiceAddr})
            .then(invoiceResult => addressIDs.invoice_address_id = invoiceResult.id);
        }

        if (shop.shipping_address_id) {
          await crud.data.update({entity: 'address', id: shop.shipping_address_id, update: shippingAddr});
          addressIDs.shipping_address_id = shop.shipping_address_id;
        } else {
          shippingAddr = {...shippingAddr, owner_id: ownerId};
          await crud.data.create({entity: 'address', item: shippingAddr})
            .then(shippingResult => addressIDs.shipping_address_id = shippingResult.id);
        }

        resolve(addressIDs);
      });
    }

    let newUserId = null;
    let withoutErrors = true;

    if (items.id) {
      if (items.password?.length > 0) {
        try {
          await bl.sudo_change_password({new_password: items.password, user_id: items.id});
        } catch (error) {
          withoutErrors = false;
          setErrors(error);
        }
      }
      if (!withoutErrors) {
        return;
      }

      let existErrors = [];
      if (items.email) {
        try {
          let check = await crud.data.read({entity: "user", filter: crud.filter.and(
            {property: "email", operator: "equals", value: items.email},
            {property: "id",    operator: "notEquals", value: items.id},
            {property: "is_admin", operator: "equals", value: '0'},
            {property: "is_sales", operator: "equals", value: '0'},
            {property: "is_field_sales", operator: "equals", value: '0'},
            {property: "is_online_sales", operator: "equals", value: '0'}
          )});
          if (check) {
            withoutErrors = false;
            existErrors = [...existErrors, {message: 'E-Mail-Adresse bereits vorhanden'}];
          }
        } catch (error) {
          setErrors(error);
          withoutErrors = false;
        }
      }
      if (items.username) {
        try {
          let check = await crud.data.read({entity: "user", filter: crud.filter.and(
            {property: "username", operator: "equals", value: items.username},
            {property: "id",    operator: "notEquals", value: items.id},
            {property: "is_admin", operator: "equals", value: '0'},
            {property: "is_sales", operator: "equals", value: '0'},
            {property: "is_field_sales", operator: "equals", value: '0'},
            {property: "is_online_sales", operator: "equals", value: '0'}
          )});
          if (check) {
            withoutErrors = false;
            existErrors = [...existErrors, {message: 'Benutzername bereits vorhanden'}];
          }
        } catch (error) {
          setErrors(error);
          withoutErrors = false;
        }
      }
      if (!items.email && !items.username) {
        setErrors([{message: 'Es muss eine E-Mail-Adresse oder ein Benutzername angegeben werden'}]);
        return;
      }
      if (existErrors.length > 0) {
        setErrors(existErrors);
      }
      if (withoutErrors) {
        delete items.password;
        crud.data.update({entity: 'user', id: items.id, update: {...items, updated_by_source: "admin"}})
          .then(() => setSuccessMessage('geändert'))
          .catch(errors => {
            setErrors(errors);
            withoutErrors = false;
          })
          .finally(() => setSubmitting(false));
      }
    } else {
      if (!items.email && !(items.password && items.username)) {
        setErrors([{message: 'Es muss eine E-Mail-Adresse oder ein Benutzername mit Passwort angegeben werden'}]);
        return;
      }

      await bl.create_user({
        user: {...items, created_by_source: "admin"},
        url: config.SHOP_URL + '/passwort-zuruecksetzen/TOKEN'
      })
        .then(userIdResult => {
          setUserProps({...userProps,  id: userIdResult});
          newUserId = userIdResult;
        })
        .catch(errors => {
          setErrors(errors);
          withoutErrors = false;
        });
    }

    if (pos.length > 0 && withoutErrors) {
      await asyncMap(pos, (point => {
        uploadAddresses(point, userProps.id ?? newUserId)
          .then((result) => {
            let item = {
              customer_number: point.customer_number,
              abbreviation: point.abbreviation,
              class: point.class,
              invoice_address_id: result.invoice_address_id,
              shipping_address_id: result.shipping_address_id,
              owner_id: userProps.id || newUserId
            };
            if (point.id) {
              crud.data.update({entity: 'point_of_sale', id: point.id, update: item})
                .catch(errors => {
                  setErrors(errors);
                  withoutErrors = false;
                });
            } else {
              crud.data.create({entity: 'point_of_sale', item: item})
                .catch(errors => {
                  setErrors(errors);
                  withoutErrors = false;
                });
            }
          })
          .catch(errors => {
            setErrors(errors);
            withoutErrors = false;
          })
          .finally(() => {
            if (withoutErrors){
              setSuccessMessage('erstellt');
            }
          });
      }));
    } else if (withoutErrors) {
      setSuccessMessage('erstellt');
    }
  };
  const addPOS = (point, userValues = {}) => {
    let values = {
      ...userProps,
      username: userValues?.username ?? '',
      email: userValues?.email ?? '',
      first_name: userValues?.first_name ?? '',
      last_name: userValues?.last_name ?? ''
    };
    if (Object.keys(point).length === 0) {
      point = {...point, owner_id: null};
    }

    setUserProps(values);
    setEditPOS(point);

    if (
      (
        !point.hasOwnProperty('invoice_address')
        && !point.hasOwnProperty('shipping_address')
      )
      ||
      (
        point.invoice_address.name === point.shipping_address.name
        && point.invoice_address.street === point.shipping_address.street
        && point.invoice_address.postal_code === point.shipping_address.postal_code
        && point.invoice_address.city === point.shipping_address.city
      )
    ) {
      setCopyInvoiceAddress(true);
    } else {
      setCopyInvoiceAddress(false);
    }
  };

  const createNewPOS = (values) => {
    let entry = {};

    entry.customer_number = values.customer_number !== '' ? values.customer_number : '';
    entry.abbreviation = values.abbreviation !== '' ? values.abbreviation : null;
    entry.class = values.class !== '' ? values.class : null;
    entry.id = editPOS.id ?? null;
    entry.editId = editPOS.editId ?? editId;
    setEditId(editId + 1);

    entry.invoice_address_id = editPOS.invoice_address_id ?? null;
    entry.invoice_address = {};
    entry.invoice_address.name = values.invoice_name;
    entry.invoice_address.street = values.invoice_street;
    entry.invoice_address.postal_code = values.invoice_postal_code;
    entry.invoice_address.city = values.invoice_city;

    if (copyInvoiceAddress) {
      entry.shipping_address_id = editPOS.shipping_address_id ?? null;
      entry.shipping_address = entry.invoice_address;
    } else {
      entry.shipping_address_id = editPOS.shipping_address_id ?? null;
      entry.shipping_address = {};
      entry.shipping_address.name = values.shipping_name;
      entry.shipping_address.street = values.shipping_street;
      entry.shipping_address.postal_code = values.shipping_postal_code;
      entry.shipping_address.city = values.shipping_city;
    }

    setPos([...pos.filter(shop => ((shop.editId !== entry.editId) && (shop.id !== entry.id))), entry]);
    setEditPOS({});
  };

  const deletePOS = () => {
    crud.data.delete({
      entity: 'point_of_sale',
      id: selectedPos.id,
    })
      .then(() => {
        setDeletePosModalOpen(false);
        setSelectedPos({});
        setDeletePosConfirmedModalOpen(true);
        setSuccessMessageForDelete('gelöscht');
      })
      .catch(errors => {
        setErrors(errors);
      });
  };

  useEffect(() => {
    if (userProps.id) {
      setShowLoading(false);
      crud.data.bulk.read({
        entity: 'point_of_sale', page_size: 100,filter: {property: "owner_id", operator: "equals", value: userProps.id}
      })
        .then( async result =>
          setPos(await crud.expand(
            result.items,
            ['invoice_address:address', 'shipping_address:address', 'owner:user']
          ))
        )
        .catch(errors => setErrors(errors))
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, [crud, userProps.id, selectedPos]);

  return (
    <>
      { !showLoading &&
        <Modal className="CustomerEdit" title={userProps.id ? "Benutzer Bearbeiten" : "Benutzer Anlegen"}
          onDismiss={onDismiss}>
          {Object.keys(editPOS).length === 0 &&
          <>
            <Formik initialValues={initialUserValues} onSubmit={submit} validate={() => setErrors([])}
              validationSchema={validate}>
              {(fields) => (
                <Form>
                  <Row>
                    <Button inverted onClick={onDismiss} text="Abbrechen"/>
                    <Button color="primary" type="submit" text="Speichern"/>
                  </Row>
                  <Row columns={{default: 1, lg: 2}} gap='1'>
                    <TextInput value={userProps.username} name="username" label="Benutzername" id="username"/>
                    <TextInput value={userProps.email} name="email" label="E-Mail" id="email"/>
                    <TextInput value={userProps.first_name} name="first_name" label="Vorname" id="first_name"/>
                    <TextInput value={userProps.last_name} name="last_name" label="Nachname" id="last_name"/>
                    <Checkbox value={parseInt(userProps.is_credit_excluded)} name="is_credit_excluded">
                      Von Punkten ausschließen
                    </Checkbox>
                    <PasswordInput name="password" label="Neues Passwort"/>
                  </Row>
                  <span>{userProps.id ? "Erstellt am: " + new Date(userProps.created_at)
                    .toLocaleString('de-DE', {
                      timeZone: 'Europe/Berlin',
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    }) + ' Uhr durch ' + displaySource(userProps.created_by_source) : ""}</span>
                  {userProps.id && <div className="mt-025">
                   Zuletzt bearbeitet: {userProps.updated_at ?  new Date(userProps.updated_at)
                      .toLocaleString('de-DE', {
                        timeZone: 'Europe/Berlin',
                        hour: '2-digit',
                        minute: '2-digit',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      }) + ' Uhr durch ' + displaySource(userProps.updated_by_source) : "–"}
                  </div>}
                  <Button className="add-button" text="Geschäft hinzufügen" onClick={() => addPOS({}, fields.values)}/>
                  <div className="pos">
                    {pos.length > 0 &&
                    <>
                      <Row columns={{default: 1, lg: 3}} gap='1'>
                        <span className="strong info-head">Info</span>
                        <span className="strong">Rechnungsadresse</span>
                        <span className="strong">Lieferadresse</span>
                      </Row>
                      {pos.map((shop, index) =>
                        <Row columns={{default: 1, lg: 3}} gap='1' key={index} className="address-box">
                          <div className="info" title={`${shop.customer_number}\n${shop.abbreviation}\n${shop.class}`}>
                            <p>{shop.customer_number}</p>
                            <p>{shop.abbreviation} </p>
                            <p>{shop.class}</p>
                          </div>
                          <div title={
                            `${shop.invoice_address?.name}\n${shop.invoice_address?.street}\n` +
                            `${shop.invoice_address?.postal_code} ${shop.invoice_address?.city}`
                          }>
                            <p>{shop.invoice_address?.name}</p>
                            <p>{shop.invoice_address?.street}</p>
                            <p>{shop.invoice_address?.postal_code} {shop.invoice_address?.city}</p>
                          </div>
                          <div title={
                            `${shop.shipping_address?.name}\n${shop.shipping_address?.street}\n` +
                            `${shop.shipping_address?.postal_code} ${shop.shipping_address?.city}`
                          }>
                            <p>{shop.shipping_address?.name}</p>
                            <p>{shop.shipping_address?.street}</p>
                            <p>{shop.shipping_address?.postal_code} {shop.shipping_address?.city}</p>
                          </div>
                          <span title="Geschäft bearbeiten" className="edit" 
                            onClick={() => {shop.index = index; addPOS(shop, fields.values);}}>
                            <Edit/>
                          </span>
                          <span title="Geschäft löschen" className="delete" 
                            onClick={() => {setSelectedPos(shop); setDeletePosModalOpen(true);}}>
                            <Trash/>
                          </span>
                        </Row>
                      )}
                    </>}
                  </div>
                </Form>)}
            </Formik>
          </>}
          {Object.keys(editPOS).length > 0 &&
              <div className="POS-edit">
                <Formik
                  initialValues={{
                    customer_number: editPOS.customer_number ? editPOS.customer_number : '',
                    abbreviation: editPOS.abbreviation ? editPOS.abbreviation : '',
                    class: editPOS.class ? editPOS.class : '',
                    invoice_name: editPOS.invoice_address?.name ? editPOS.invoice_address?.name : '',
                    invoice_street: editPOS.invoice_address?.street ? editPOS.invoice_address?.street : '',
                    invoice_postal_code: editPOS.invoice_address?.postal_code ?
                      editPOS.invoice_address?.postal_code : '',
                    invoice_city: editPOS.invoice_address?.city ? editPOS.invoice_address?.city : '',
                    shipping_name: editPOS.shipping_address?.name ? editPOS.shipping_address?.name : '',
                    shipping_street: editPOS.shipping_address?.street ? editPOS.shipping_address?.street : '',
                    shipping_postal_code: editPOS.shipping_address?.postal_code ?
                      editPOS.shipping_address?.postal_code : '',
                    shipping_city: editPOS.shipping_address?.city ? editPOS.shipping_address?.city : '',
                    index : editPOS.index ? editPOS.index : ''
                  }}
                  validationSchema={posvalidate} onSubmit={createNewPOS} validate={() => setErrors([])}
                >
                  {() => (
                    <Form>
                      <div className="POS-create">
                        <Row>
                          <TextInput name="customer_number" label="Kundennummer" required/>
                          <TextInput name="abbreviation" label="Abkürzung"/>
                          <TextInput name="class" label="Klasse"/>
                        </Row>
                        <Row columns={{default: 1, lg: 2}} gap='1'>
                          <div>
                            <span className="strong">{
                              `Rechnungsadresse${copyInvoiceAddress ? ' & Lieferadresse' : ''}`
                            }</span>
                            <TextInput name="invoice_name" label="Bezeichnung" required className="mt-1"/>
                            <TextInput name="invoice_street" label="Straße" required/>
                            <Row columns={{default: 1, lg: 2}} gap='1'>
                              <TextInput name="invoice_postal_code" label="Postleitzahl" required/>
                              <TextInput name="invoice_city" label="Stadt" required/>
                            </Row>
                          </div>
                          {
                            !copyInvoiceAddress &&
                            <div>
                              <span className="strong">Lieferadresse</span>
                              <TextInput name="shipping_name" label="Bezeichnung" required  className="mt-1"/>
                              <TextInput name="shipping_street" label="Straße" required/>
                              <Row columns={{default: 1, lg: 2}} gap='1'>
                                <TextInput name="shipping_postal_code" label="Postleitzahl" required/>
                                <TextInput name="shipping_city" label="Stadt" required/>
                              </Row>
                            </div>
                          }
                        </Row>
                        <input type="checkbox" id="copyAddress" checked={!copyInvoiceAddress}
                          onChange={event => setCopyInvoiceAddress(!event.target.checked)}/>
                        <label htmlFor="copyAddress">abweichende Lieferadresse verwenden</label>
                      </div>
                      <Row>
                        <Button inverted onClick={() => setEditPOS({})} text="Abbrechen"/>
                        <Button type="submit" text={editPOS.owner_id ? "bearbeiten" : "hinzufügen"}/>
                      </Row>
                    </Form>)}
                </Formik>
              </div>}
        </Modal>}

      <Modal title="Geschäft löschen" show={deletePosModalOpen} 
        onDismiss={() => {setSelectedPos({}); setDeletePosModalOpen(false);}}>
        <p>Soll das Geschäft „{selectedPos?.invoice_address?.name}“ wirklich gelöscht werden?</p>
        <Row>
          <Button className="danger" onClick={deletePOS} text={"Löschen"}/>
          <Button  onClick={() => {setSelectedPos({}); setDeletePosModalOpen(false);}} text="Behalten"/>
        </Row>
      </Modal>
      {
        successMessage.length > 0 &&
        <Modal className={"SuccessMessage"} title="Speichern" onDismiss={onDismiss}>
          <h3>Speichern Erfolgreich</h3>
          <p className="text-right"><Button text="OK" onClick={onDismiss} inline/></p>
        </Modal>
      }
      {
        deletePosConfirmedModalOpen && successMessageForDelete.length > 0 &&
          <Modal className={"SuccessMessageForDelete"} title="Löschen"
            onDismiss={() => setDeletePosConfirmedModalOpen(false)}>
            <h3>Das Geschäft wurde erfolgreich gelöscht.</h3>
            <p className="text-right"><Button text="OK" 
              onClick={() => setDeletePosConfirmedModalOpen(false)} inline/></p>
          </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>);
}
